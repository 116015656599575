<template>
  <div>
    <b-row>
      <b-col
        sm="12"
      >
        <analytics-congratulation :data="user" />
      </b-col>
      <b-col sm="12">
        <b-row>
          <b-col sm="4">
            <StatisticCardHorizontal
              :statistic="getCounts.created"
              statistic-title="Emission"
              icon="TrendingUpIcon"
              color="info"
            />
          </b-col>
          <b-col sm="4">
            <StatisticCardHorizontal
              :statistic="getCounts.pending"
              statistic-title="Attente"
              icon="ActivityIcon"
              color="warning"
            />
          </b-col>
          <b-col sm="4">
            <StatisticCardHorizontal
              :statistic="getCounts.waiting"
              statistic-title="Traitement"
              icon="ActivityIcon"
              color="warning"
            />
          </b-col>
          <b-col sm="6">
            <StatisticCardHorizontal
              :statistic="getCounts.failed"
              statistic-title="Echec"
              icon="FrownIcon"
              color="danger"
            />
          </b-col>
          <b-col sm="6">
            <StatisticCardHorizontal
              :statistic="getCounts.success"
              statistic-title="Validées"
              icon="TrendingDownIcon"
              color="primary"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card-actions
          ref="electronicCard"
          title="Portefeuille electronique"
          action-refresh
          @refresh="getAllCurrencies()"
        >
          <b-table-simple
            caption-top
            responsive
            hover
          >
            <b-tr
              v-for="(item, index) in electronicCurrencies"
              :key="index"
            >
              <b-td>
                <img
                  :src="item.icones"
                  class="mr-1 float-left"
                  width="25"
                  height="25"
                >
                {{ item.libelle }}
              </b-td>
              <b-td>
                {{ item.solde }}
              </b-td>
            </b-tr>
          </b-table-simple>
        </b-card-actions>
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card-actions
          ref="manualCard"
          title="Manuel"
          action-refresh
          @refresh="getAllCurrencies()"
        >
          <b-table-simple
            caption-top
            responsive
            hover
          >
            <b-tr
              v-for="(item, index) in defaultCurrencies"
              :key="index"
            >
              <b-td>
                <img
                  :src="item.icones"
                  class="mr-1 float-left"
                  width="25"
                  height="25"
                >
                {{ item.libelle }}
              </b-td>
              <b-td>
                {{ item.solde }}
              </b-td>
            </b-tr>
          </b-table-simple>
        </b-card-actions>
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card-actions
          ref="mobileCard"
          title="Mobiles"
          action-refresh
          @refresh="getAllCurrencies()"
        >
          <b-table
            caption-top
            responsive
            hover
            :current-page="currentMobilePage"
            :per-page="mobileperPage"
            :items="mobileCurrencies"
            :fields="mobileFields"
          >
            <template v-slot:cell(image)="data">
              <img
                :src="data.item.icones"
                class="mr-1 float-left"
                width="25"
                height="25"
              >
              {{ data.item.libelle }}
            </template>
          </b-table>
          <div
            v-if="mobileCurrencies.length > 0"
            class="mt-2"
          >
            <b-pagination
              v-model="currentMobilePage"
              :total-rows="totalMobileItems"
              :per-page="mobileperPage"
              first-number
              last-number
              align="center"
            />
          </div>
        </b-card-actions>
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card-actions
          ref="cryptoCard"
          title="Cryptomonnaie"
          action-refresh
          @refresh="getAllCurrencies()"
        >
          <b-table
            caption-top
            responsive
            hover
            :current-page="currentCryptoPage"
            :per-page="cryptoperPage"
            :items="cryptoCurrencies"
            :fields="cryptoFields"
          >
            <template v-slot:cell(image)="data">
              <img
                :src="data.item.icones"
                class="mr-1 float-left"
                width="25"
                height="25"
              >
              {{ data.item.libelle }}
            </template>
          </b-table>
          <div
            v-if="cryptoCurrencies.length > 0"
            class="mt-2"
          >
            <b-pagination
              v-model="currentCryptoPage"
              :total-rows="totalCryptoItems"
              :per-page="cryptoperPage"
              first-number
              last-number
              align="center"
            />
          </div>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="sysStats.length > 0"
        sm="12"
        md="6"
        lg="6"
      >
        <b-card>
          <b-card-title class="mb-1">
            Transaction systeme
          </b-card-title>
          <b-card-sub-title class="mb-2">
            Un apercu statistique des transactions du systeme.
          </b-card-sub-title>
          <vue-apex-charts
            type="pie"
            height="300"
            :options="sysOptions"
            :series="sysSeries"
          />
        </b-card>
      </b-col>
      <!-- <b-col
        v-if="stats.length > 0"
        sm="12"
        md="6"
        lg="6"
      >
        <b-card>
          <b-card-title class="mb-1">
            Vos transactions
          </b-card-title>
          <b-card-sub-title class="mb-2">
            Un apercu statistique de vos transactions.
          </b-card-sub-title>
          <vue-apex-charts
            type="donut"
            height="300"
            :options="userOptions"
            :series="userSeries"
          />
        </b-card>
      </b-col> -->
      <b-col
        v-if="users.length > 0"
        sm="12"
        md="6"
        lg="6"
      >
        <b-card>
          <b-card-title class="mb-1">
            Utilisateurs
          </b-card-title>
          <b-card-sub-title class="mb-2">
            Un apercu statistique de vos utilisateurs.
          </b-card-sub-title>
          <vue-apex-charts
            type="donut"
            height="300"
            :options="userListOptions"
            :series="userListSeries"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <Validation />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        lg="6"
        sm="12"
      >
        <b-card-actions
          ref="refreshCard"
          title="Demande d'aide"
          action-refresh
          @refresh="getSupportByState()"
        >
          <div
            v-if="loaded"
            class="d-flex justify-content-center"
          >
            <div class="align-items-center">
              <b-spinner variant="primary" />
            </div>
          </div>
          <div v-if="!loaded && supports.length > 0">
            <app-timeline>
              <app-timeline-item
                v-for="(item, index) in supports"
                :key="index"
                variant="primary"
              >
                <div class="d-flex justify-content-between flex-sm-row flex-row mb-sm-0 mb-1">
                  <h6>{{ item.title }}</h6>
                  <small class="timeline-item-time text-nowrap text-muted ml-1">{{ item.created_at | moment("DD/MM/YY HH:mm") }}</small>
                </div>
                <p>{{ item.message }}</p>
                <p class="text-info font-weight-normal">
                  {{ item.ticket }}</p>
              </app-timeline-item>
            </app-timeline>
          </div>
          <div v-if="!loaded && supports.length === 0">
            <div class="d-flex justify-content-center">
              <div class="align-items-center">
                <p class="text-primary text-uppercase font-weight-bold">
                  Aucune demande d'aide en attente</p>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="float-left">
              <b-form-select
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              align="right"
            />
          </div>
        </b-card-actions>
      </b-col>
      <b-col
        v-if="reviews.length > 0"
        md="6"
        lg="6"
        sm="12"
      >
        <b-card title="Avis et Note de nos utilisateurs">
          <app-timeline>
            <app-timeline-item
              v-for="(item, index) in reviews"
              :key="index"
              icon="UserIcon"
              variant="primary"
            >
              <div class="d-flex justify-content-between flex-sm-row flex-row mb-sm-0 mb-1">
                <h6 class="text-small text-lowercase">
                  {{ item.name.split(" ")[0] }}
                </h6>
                <small class="timeline-item-time text-nowrap text-muted ml-1">
                  {{ item.created_at | moment("DD/MM/YY HH:mm") }}</small>
              </div>
              <span class="m-0 p-0">
                <b-form-rating
                  id="rating-readonly"
                  class="p-0"
                  no-border
                  :value="item.note"
                  readonly
                  inline
                  :variant="item.note >= 3 ? 'success' : 'danger'"
                />
              </span>
              <p class="text-small">
                {{ item.review }}
              </p>
            </app-timeline-item>
          </app-timeline>
          <b-pagination
            v-model="currentNotePage"
            :total-rows="totalNoteItems"
            :per-page="perPageNote"
            first-number
            last-number
            align="right"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Validation from '@/components/Validation.vue'
// import { kFormatter } from '@core/utils/filter'
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
// colors

const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#b61d1d',
    series3: '#126e51',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}
export default {
  components: {
    StatisticCardHorizontal,
    AnalyticsCongratulation,
    VueApexCharts,
    AppTimeline,
    AppTimelineItem,
    BCardActions,
    Validation,
  },
  data() {
    return {
      currentUser: this.$store.getters.currentUser,
      mobileFields: [
        {
          key: 'image',
          label: 'Moyen',
        },
        { key: 'solde', label: 'Solde' },
      ],
      cryptoFields: [
        {
          key: 'image',
          label: 'Moyen',
        },
        { key: 'solde', label: 'Solde' },
      ],
      supports: [],
      allCurrencies: [],
      cryptoCurrencies: [],
      manualCurrencies: [],
      electronicCurrencies: [],
      mobileCurrencies: [],
      defaultCurrencies: [],
      loaded: false,
      currentPage: 1,
      perPage: 15,
      currentMobilePage: 1,
      mobileperPage: 4,
      currentCryptoPage: 1,
      cryptoperPage: 4,
      currentNotePage: 1,
      perPageNote: 3,
      pageOptions: [5, 10, 15, 20],
      totalItems: 0,
      totalMobileItems: 0,
      totalCryptoItems: 0,
      totalNoteItems: 0,
      stats: [],
      sysStats: [],
      reviews: [],
      users: [],
      user: {
        name: `${this.$store.getters.currentUser.name} ${this.$store.getters.currentUser.surname}`,
      },
    }
  },
  computed: {
    getCounts() {
      const data = {
        created: 0,
        pending: 0,
        success: 0,
        waiting: 0,
        failed: 0,
      }
      if (this.stats.length > 0) {
        this.stats.forEach(element => {
          if (element.state === 'CREATED') {
            data.created = Number(element.data)
          }
          if (element.state === 'PENDING') {
            data.pending = Number(element.data)
          }
          if (element.state === 'FAILED') {
            data.failed = Number(element.data)
          }
          if (element.state === 'SUCCESSFUL') {
            data.success = Number(element.data)
          }
          if (element.state === 'WAITING') {
            data.waiting = Number(element.data)
          }
        })
      }
      return data
    },
    userSeries() {
      return Object.values(this.getCounts)
    },
    userOptions() {
      return {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: [
          chartColors.donut.series1,
          chartColors.donut.series5,
          chartColors.donut.series3,
          chartColors.donut.series4,
          chartColors.donut.series2,
        ],
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: true,
                  fontSize: '1.5rem',
                  label: 'Total',
                },
              },
            },
          },
        },
        labels: ['Initié', 'En cours', 'Validé', 'Traitement', 'Echec'],
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  expandOnClick: true,
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '12px',
                      },
                      value: {
                        fontSize: '12px',
                      },
                      total: {
                        fontSize: '14px',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      }
    },
    getUserList() {
      const data = {
        created: 0,
        pending: 0,
        success: 0,
        waiting: 0,
        failed: 0,
      }
      if (this.users.length > 0) {
        this.users.forEach(element => {
          if (element.state === 'CREATED') {
            data.created = Number(element.data)
          }
          if (element.state === 'NEW_DOCUMENT') {
            data.pending = Number(element.data)
          }
          if (element.state === 'PENDING') {
            data.pending = Number(element.data)
          }
          if (element.state === 'WAITING') {
            data.waiting = Number(element.data)
          }
          if (element.state === 'VALID') {
            data.success = Number(element.data)
          }
        })
      }
      return data
    },
    userListSeries() {
      return Object.values(this.getUserList)
    },
    userListOptions() {
      return {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: [
          chartColors.donut.series2,
          chartColors.donut.series1,
          chartColors.donut.series3,
          chartColors.donut.series4,
          chartColors.donut.series5,
        ],
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: true,
                  fontSize: '1.5rem',
                  label: 'Total',
                },
              },
            },
          },
        },
        labels: ['Non validé', 'En instance', 'Validé', 'En traitement', 'En validation'],
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  expandOnClick: true,
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '12px',
                      },
                      value: {
                        fontSize: '12px',
                      },
                      total: {
                        fontSize: '14px',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      }
    },
    getSysCounts() {
      const data = {
        created: 0,
        success: 0,
        waiting: 0,
        failed: 0,
      }
      if (this.sysStats.length > 0) {
        this.sysStats.forEach(element => {
          if (element.state === 'CREATED') {
            data.created = Number(element.data)
          }
          if (element.state === 'SUCCESSFUL') {
            data.success = Number(element.data)
          }
          if (element.state === 'WAITING') {
            data.waiting = Number(element.data)
          }
          if (element.state === 'FAILED') {
            data.failed = Number(element.data)
          }
        })
      }
      return data
    },
    sysSeries() {
      return Object.values(this.getSysCounts)
    },
    sysOptions() {
      return {
        colors: [chartColors.donut.series1, chartColors.donut.series3, chartColors.donut.series4, chartColors.donut.series2],
        plotOptions: {
          radialBar: {
            size: 185,
            hollow: {
              size: '25%',
            },
            track: {
              margin: 15,
            },
            dataLabels: {
              name: {
                fontSize: '14px',
                fontFamily: 'Montserrat',
              },
              value: {
                fontFamily: 'Montserrat',
                fontSize: '14px',
                formatter(val) {
                  return `${val}`
                },
              },
              total: {
                show: true,
                fontSize: '12px',
                label: 'Total',
              },
            },
          },
        },
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['Initiées', 'Réussies', 'En Traitement', 'Echec'],
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.countExhange()
        this.getAllCurrencies()
        this.countSystemExchange()
        this.getSupportByState()
        this.getReviews()
        this.getUsers()
      },
    },
    currentPage(val) {
      if (val) {
        this.getSupportByState()
      }
    },
    perPage(val) {
      if (val) {
        this.getSupportByState()
      }
    },
    currentNotePage(val) {
      if (val) {
        this.getReviews()
      }
    },
    perPageNote(val) {
      if (val) {
        this.getReviews()
      }
    },
  },
  methods: {
    // kFormatter,
    async getAllCurrencies() {
      this.allCurrencies = await
      this.$http
        .get('/currencies/all', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          this.$refs.cryptoCard.showLoading = false
          this.$refs.manualCard.showLoading = false
          this.$refs.electronicCard.showLoading = false
          this.$refs.mobileCard.showLoading = false
          const { data } = res.data
          this.cryptoCurrencies = data.filter(item => item.is_crypto)
          this.totalCryptoItems = this.cryptoCurrencies.length
          this.mobileCurrencies = data.filter(item => item.provider === 'INTOUCH')
          this.totalMobileItems = this.mobileCurrencies.length
          this.electronicCurrencies = data.filter(item => (item.provider === 'PERFECT' || item.provider === 'PAYEER'))
          this.defaultCurrencies = data.filter(item => (item.provider === 'DEFAULT'))
        })
        .catch(() => [])
    },
    async countExhange() {
      this.stats = await this.$http
        .get(`/stats-by-state-and-owner/${this.$store.getters.currentUser.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => res.data)
        .catch(() => [])
    },
    async getUsers() {
      this.users = await this.$http
        .get('/users/stats',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => res.data)
        .catch(() => [])
    },
    async countSystemExchange() {
      this.sysStats = await this.$http
        .get('/stats-by-state',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => res.data)
        .catch(() => [])
    },
    async getSupportByState() {
      this.loaded = true
      this.supports = await this.$http
        .get(`/support-all-by-state/CREATED/${this.perPage}?page=${this.currentPage}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          const { data } = res
          this.loaded = false
          this.totalItems = data.meta.total
          this.$refs.refreshCard.showLoading = false
          return data.data
        })
        .catch(() => {
          this.loaded = false
          this.$refs.refreshCard.showLoading = false
          return []
        })
    },
    async getReviews() {
      this.reviews = await this.$http
        .get(`/reviews/all-state/true/${this.perPageNote}?page=${this.currentNotePage}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => {
          this.totalNoteItems = res.data.meta.total
          return res.data.data
        })
        .catch(() => [])
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
